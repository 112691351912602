import React from 'react';
import './companyInfo.css';
function CompanyInfo() {
  return (
    <div className={'company-info'}>

        <br/>
        <div>
            <p className={'company-info__text--cursive'}>Мы - команда профессиональных юристов, которые предоставляют широкий спектр юридических услуг в области гражданского права, в городе Москве. Наша задача - защитить права и интересы наших клиентов.</p>
            <p> Спектр услуг</p>
            <ul>
                <li>Консультации и представительство в суде по гражданским делам;</li>
                <li>Составление и анализ договоров;</li>
                <li>Досудебное урегулирование споров (медиация);</li>
                <li>Защита прав потребителей;</li>
                <li>Недвижимость: купля-продажа, аренда, споры с застройщиками по договорам ДДУ;</li>
                <li>Консултация и защита прав потерпевшего при причинении вреда личности или имуществу;</li>
                <li>Споры со страховыми  компаниями по возмещению ущерба в результате ДТП </li>
                <li>Другие юридические услуги:</li>
            </ul>
             <pre> Письма, Заявления, Претензии, Жалобы, Ходатайства. Экспресс консультации. Консультации с разработкой стратегии</pre>
            <p className={'company-info__text--cursive'}>
                Наши юристы не только обладают профессиональными навыками, но и стремятся к высокой моральной ответственности в своей работе.
                Если вам нужна юридическая помощь и вы готовы работать с командой, которая подходит к этому вопросу с уважением и профессионализмом, то вы обратились по адресу.
            </p>
        </div>

    </div>
  );
}

export default CompanyInfo;