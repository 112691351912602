import service0 from "../_assets/service0.jpg";
import service1 from "../_assets/service1.jpg"
import service2 from "../_assets/service2.jpg"
import service3 from "../_assets/servise3.jpg"
import service4 from "../_assets/service4.jpg"
import service5 from "../_assets/service5.jpg"

export const services = [
  {
    id: 'accident',
    imageSrc:service0,
    title: 'Юридические услуги при ДТП',
    subtitle: '"Юридические услуги связанные с ДТП и споры со страховыми компаниями. Экспертные услуги при расчете ущерба от ДТП. Составление исковых заявлений. Сопровождение дела в судах всех инстанций."',
    flipped:false,
    mainInfo: [
      '-обжалование постановлений ГИБДД',
      '-проведение независимой экспертизы',
      '-возмещение ущерба по КАСКО и ОСАГО после ДТП (споры со страховыми компаниями)',
      '-участие в делах, связанных с причинением вреда здоровью в результате ДТП',
      '-жалобы на постановления об административыных нарушениях в сфере безопасности дорожного движени',
    ],
    isListInfo: true,
  },
  {
    id: 'administrative_disputes',
    imageSrc:service1,
    title: 'Гражданско-правовая помощь',
    subtitle: '"Гражданское право объединяет правовые нормы, регулирующие имущественные, а также связанные и несвязанные с ними личные неимущественные отношения, которые возникают между различными организациями и физическими лицами, а также между отдельными гражданами."',
    flipped:true,
    mainInfo: [
      '-Представительство интересов по спорам с ЖКХ, ТСЖ',
      '-Представительство интересов по спорам связанных в части возмещения ущерба недвижимости',
      '-Споры с застройщиком по ДДУ',
      '-Взыскание неустойки за несвоевременную сдачу объекта долевого строительства',
      '-Компенсация затрат на устранение недостатков при передачи объекта долевого строительства',
      '-Взыскание штрафа по Закону о защите прав потребителей.'
    ],
    isListInfo: true
  },
  {
    id: 'organizations_support',
    imageSrc:service2,
    title: 'Юридическое сопровождение организаций',
    subtitle: '"Мы работаем с предпринимателями и юридическими лицами, осуществляем юридическое сопровождение бизнеса, оградим вас от неприятностей, оптимизируюем налогообложение."',
    flipped: false,
    mainInfo: `Любая крупная компания содержит в штате юристов либо целый отдел, 
    поскольку эффективность деятельности очень часто зависит от работы данных специалистов. 
    Однако не каждый предприниматель имеет финансово такую возможность, поэтому абонентское обслуживание является 
    удобной альтернативой. За вполне приемлемые деньги вы получаете в свое распоряжение юридические услуги, 
    виды зависят от потребностей. Чаще всего это работа с документами, анализ договоров, выдача заключений по тем или иным вопросам, 
    взыскание задолженностей с контрагентов, взаимодействие с государственными органами, получение разрешений, лицензий. Все виды услуг для юридических лиц могут 
    оказываться на постоянной основе за фиксированную плату либо в виде разовых обращений. Абонентское обслуживание включает полный комплекс и наиболее удобно, 
    поскольку есть возможность постоянной правовой поддержки. Стоимость определяется в зависимости от объема выполненных работ, количества часов и изготовленных документов. 
    Консалтинг и поддержка при создании и регистрации новых предприятий, проведении сделок поглощения и слияния, а также иных вариантов оптимизации структуры бизнеса, 
    при проведении процедуры банкротства или ликвидации.
    Комплексное юридическое обслуживание (оказание всего спектра правовой помощи в рамках абонентского договора).`,
    isListInfo: false
  },
  {
    id: 'documents_support',
    imageSrc: service4,
    title: 'Решение правовых проблем, подбор документов для решения конкретной ситуации',
    subtitle: '"В жизни часто возникают такие вопросы и ситуации, при которых необходим анализ, варианты действий и принятие правильного решения. Вопросы возникающие часто, где есть опыт его решения и наработки, достаточно обратиться к знакомому документу."',
    flipped:true,
    additionalInfo: 'Для решения возникающих в жизни вопросов и ситуаций, требующих юридических действий, выбора правильного варианта реагирования, определения нужных в данной ситуации мер, как правило, необходимо обратиться к соответствующим нормативным и подзаконным актам. Правильный подбор документов зачастую играет определяющую роль для достижения нужного результата.',
    isListInfo: true,
    mainTextInfo: 'Как правило, чтобы хорошо разобраться в ситуации, необходимо изучить целый ряд документов, в том числе: ',
    mainInfo: [
      '- нормативно-правовые акты (НПА), чтобы ознакомиться с правовыми нормами, регулирующими правоотношения, из которых возникла проблема;',
      '- судебную практику, то есть конкретные судебные решения, принятые в аналогичных спорных ситуациях с тем или иным результатом, которые служат источником информации о том, ' +
      'как лучше аргументировать свою позицию в суде или на переговорах;',
      '- материалы консультационного характера – комментарии НПА, разъяснения компетентных органов или специалистов, применимые к данной ситуации;',
      '- готовые формы, которые помогут составить необходимый в данной ситуации договор, заявление, иск, образцы бланков, приказов и т.п'
    ]

  },
  {
    id: 'bankruptcy',
    imageSrc: service5,
    title: 'Банкротсво физических лиц',
    subtitle: 'Основания для признания гражданина-должника несостоятельным (банкротом), порядок и условия проведения процедур, применяемых в деле о банкротстве, регулируются Федеральным законом от 26.10.2002 № 127-ФЗ "О несостоятельности (банкротстве)" (далее - Закон о банкротстве). - споровождаем бонкротсво физических лиц ' ,
    flipped:true,
    additionalInfo: 'Для решения возникающих в жизни вопросов и ситуаций, требующих юридических действий, выбора правильного варианта реагирования, определения нужных в данной ситуации мер, как правило, необходимо обратиться к соответствующим нормативным и подзаконным актам. Правильный подбор документов зачастую играет определяющую роль для достижения нужного результата.',
    isListInfo: true,
    mainTextInfo: 'Как правило, чтобы хорошо разобраться в ситуации, необходимо изучить целый ряд документов, в том числе: ',
    mainInfo: [
      '- нормативно-правовые акты (НПА), чтобы ознакомиться с правовыми нормами, регулирующими правоотношения, из которых возникла проблема;',
      '- судебную практику, то есть конкретные судебные решения, принятые в аналогичных спорных ситуациях с тем или иным результатом, которые служат источником информации о том, ' +
      'как лучше аргументировать свою позицию в суде или на переговорах;',
      '- материалы консультационного характера – комментарии НПА, разъяснения компетентных органов или специалистов, применимые к данной ситуации;',
      '- готовые формы, которые помогут составить необходимый в данной ситуации договор, заявление, иск, образцы бланков, приказов и т.п'
    ],
    isNeedMore: true,

  },
]
